import * as GQL from "@/gql";

export const allContentPages: GQL.ContentPageStructureQuery["allContentPages"] =
  [
    {
      children: [
        {
          children: [
            {
              children: [],
              id: "92918229",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "willkommen-nwv-bfk", locale: "de" },
                { value: "bienvenue-nwv-bfk", locale: "fr" },
                { value: "benvenuto-nwv-bfk", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Bankeinstieg für Lernende", locale: "de" },
                {
                  value: "Cap sur le métier de banquier pour les apprentis",
                  locale: "fr",
                },
                { value: "Ingresso in banca per apprendisti", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918179",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "willkommen", locale: "de" },
                { value: "bienvenue", locale: "fr" },
                { value: "benvenuto", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Herzlich Willkommen", locale: "de" },
                { value: "Bienvenue chez CYP", locale: "fr" },
                { value: "Benvenuti presso CYP", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918154",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "moduluebersicht-bis-2022", locale: "de" },
                { value: "apercu-des-modules", locale: "fr" },
                { value: "descrizione-dei-moduli", locale: "it" },
              ],
              _allPageNameLocales: [
                {
                  value: "Modulübersicht Lernende bis Lehrstart 2022",
                  locale: "de",
                },
                {
                  value: "Aperçu des modules – Apprentissage Orfo 2012",
                  locale: "fr",
                },
                {
                  value: "Descrizione dei moduli – Apprendistato Ofro 2012",
                  locale: "it",
                },
              ],
              cover: null,
            },
            {
              children: [],
              id: "148460483",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "moduluebersicht", locale: "de" },
                { value: "apercu-des-modules-2023", locale: "fr" },
                { value: "descrizione-dei-moduli-2023", locale: "it" },
              ],
              _allPageNameLocales: [
                {
                  value: "Modulübersicht Lernende ab Lehrstart 2023",
                  locale: "de",
                },
                {
                  value: "Aperçu des modules – Apprentissage Orfo 2023",
                  locale: "fr",
                },
                {
                  value: "Descrizione dei moduli – Apprendistato Ofro 2023",
                  locale: "it",
                },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918155",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "faq", locale: "de" },
                { value: "faq", locale: "fr" },
                { value: "faq", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "FAQ: BFK & KV BM Fokus", locale: "de" },
                { value: "FAQ: BFK", locale: "fr" },
                { value: "FAQ: BFK", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918222",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [{ value: "scouts", locale: "de" }],
              _allPageNameLocales: [{ value: "Scouts", locale: "de" }],
              cover: null,
            },
          ],
          id: "92918143",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [
            { value: "lernende", locale: "de" },
            { value: "apprentis", locale: "fr" },
            { value: "apprendisti", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Lernende", locale: "de" },
            { value: "Apprentis", locale: "fr" },
            { value: "Apprendisti", locale: "it" },
          ],
          cover: {
            customData: { "fp-x": "0.55", "fp-y": "0.5", "fp-z": "1.2" },
            url: "https://www.datocms-assets.com/25771/1671535315-cyp_220917_038.jpg",
          },
        },
        {
          children: [
            {
              children: [],
              id: "120333701",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "willkommen-nwv-kv-bm-fokus", locale: "de" },
              ],
              _allPageNameLocales: [
                { value: "Bankeinstieg für KV BM Fokus", locale: "de" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "120333706",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "willkommen-kv-bm-fokus", locale: "de" },
              ],
              _allPageNameLocales: [
                { value: "Herzlich Willkommen", locale: "de" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "120341278",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "moduluebersicht-kv-bm-fokus", locale: "de" },
              ],
              _allPageNameLocales: [
                {
                  value: "Modulübersicht KV BM Fokus ab Lehrstart 2023",
                  locale: "de",
                },
              ],
              cover: null,
            },
            {
              children: [],
              id: "fo5DoAuNTBCZtwbIBNqh8w",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "moduluebersicht-kv-bm-fokus-bis-2022", locale: "de" },
              ],
              _allPageNameLocales: [
                {
                  value: "Modulübersicht KV BM Fokus bis Lehrstart 2022",
                  locale: "de",
                },
              ],
              cover: null,
            },
            {
              children: [],
              id: "H53KFSyVRBW4EKBttJuhOw",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                {
                  value: "moduluebersicht-kv-bm-fokus-ab-lehrstart-2023",
                  locale: "de",
                },
              ],
              _allPageNameLocales: [
                {
                  value: "Modulübersicht KV BM Fokus ab Lehrstart 2023",
                  locale: "de",
                },
              ],
              cover: null,
            },
          ],
          id: "120332233",
          tweak: "",
          native: "",
          _allNativeLocales: [{ locale: "de", value: "" }],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [{ value: "kv-bm-fokus", locale: "de" }],
          _allPageNameLocales: [{ value: "KV BM Fokus", locale: "de" }],
          cover: {
            customData: { "fp-x": "0.5", "fp-y": "0.4", "fp-z": "1.1" },
            url: "https://www.datocms-assets.com/25771/1671799300-cyp_220917_313-gespiegelt.jpg",
          },
        },
        {
          children: [
            {
              children: [],
              id: "92918226",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "willkommen-nwv-bem", locale: "de" },
                { value: "bienvenue-nwv-bem", locale: "fr" },
                { value: "benvenuto-nwv-bem", locale: "it" },
              ],
              _allPageNameLocales: [
                {
                  value: "Bankeinstieg für Mittelschulabsolventen",
                  locale: "de",
                },
                {
                  value:
                    "Cap sur le métier de banquier pour les porteurs de maturité",
                  locale: "fr",
                },
                { value: "Ingresso in banca per praticanti", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918180",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "willkommen-bem", locale: "de" },
                { value: "bienvenue-bem", locale: "fr" },
                { value: "benvenuto-bem", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Herzlich Willkommen", locale: "de" },
                { value: "Bienvenue chez CYP", locale: "fr" },
                { value: "Benvenuti presso CYP", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918158",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "faq-bem", locale: "de" },
                { value: "faq-bem", locale: "fr" },
                { value: "faq-bem", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "FAQ: BEM", locale: "de" },
                { value: "FAQ: BEM", locale: "fr" },
                { value: "FAQ: BEM", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918200",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "hms-in-bem", locale: "de" },
                { value: "ec-en-bem", locale: "fr" },
                { value: "smc-nel-bem", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "HMS in BEM", locale: "de" },
                { value: "EC en BEM", locale: "fr" },
                { value: "SMC nel BEM", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918160",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "moduluebersicht-bem", locale: "de" },
                { value: "apercu-des-modules-bem", locale: "fr" },
                { value: "descrizione-dei-moduli-bem", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Modulübersicht BEM", locale: "de" },
                { value: "Aperçu des modules – BEM", locale: "fr" },
                { value: "Descrizione dei moduli – BEM", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918198",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "muendliche-pruefung", locale: "de" },
                { value: "examen-oral", locale: "fr" },
                { value: "esami-orali", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Mündliche Prüfung", locale: "de" },
                { value: "Examen oral", locale: "fr" },
                { value: "Esami orali", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918199",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "qualifikationselemente", locale: "de" },
                { value: "elements-de-qualification", locale: "fr" },
                { value: "elementi-di-qualificazione", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Qualifikationselemente", locale: "de" },
                { value: "Éléments de qualification", locale: "fr" },
                { value: "Elementi di qualificazione", locale: "it" },
              ],
              cover: null,
            },
          ],
          id: "92918150",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [
            { value: "mittelschulabsolventen", locale: "de" },
            { value: "porteurs-de-maturite", locale: "fr" },
            { value: "diplomati-con-maturita", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Mittelschulabsolventen (BEM)", locale: "de" },
            { value: "Porteurs de maturité (BEM)", locale: "fr" },
            { value: "Diplomati con maturità (BEM)", locale: "it" },
          ],
          cover: {
            customData: { "fp-x": "0.1", "fp-z": "1.3" },
            url: "https://www.datocms-assets.com/25771/1671789691-cyp_220917_170-gespiegelt.jpg",
          },
        },
        {
          children: [
            {
              children: [],
              id: "92918231",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "willkommen-nwv-bankfit", locale: "de" },
              ],
              _allPageNameLocales: [
                { value: "Ablauf Anmeldeprozess BankFIT", locale: "de" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "147447402",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [{ value: "willkommen-bankfit", locale: "de" }],
              _allPageNameLocales: [
                { value: "Herzlich Willkommen", locale: "de" },
              ],
              cover: null,
            },
          ],
          id: "92918149",
          tweak: "",
          native: "",
          _allNativeLocales: [{ locale: "de", value: "" }],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [{ value: "bankfit", locale: "de" }],
          _allPageNameLocales: [{ value: "BankFIT", locale: "de" }],
          cover: {
            customData: { "fp-x": "0.3", "fp-y": "0.4", "fp-z": "1.1" },
            url: "https://www.datocms-assets.com/25771/1671533476-cyp_220917_318.jpg",
          },
        },
        {
          children: [],
          id: "92918182",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: true,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [
            { value: "video-tutorial-cypnet", locale: "de" },
            { value: "video-tutorial-cypnet", locale: "fr" },
            { value: "video-tutorial-cypnet", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Video-Tutorial CYPnet", locale: "de" },
            { value: "Vidéo Tutorial CYPnet", locale: "fr" },
            { value: "Video-Tutorial CYPnet", locale: "it" },
          ],
          cover: null,
        },
        {
          children: [],
          id: "92918162",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: true,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [
            { value: "lerncoaching", locale: "de" },
            { value: "coaching", locale: "fr" },
            { value: "coaching", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Lerncoaching", locale: "de" },
            { value: "Coaching", locale: "fr" },
            { value: "Coaching", locale: "it" },
          ],
          cover: {
            customData: { "fp-x": "0.9", "fp-y": "0.5", "fp-z": "1." },
            url: "https://www.datocms-assets.com/25771/1671798977-cyp_220917_306-gespiegelt.jpg",
          },
        },
      ],
      id: "92918236",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: false,
      isVirtual: true,
      suppressedLocales: "",
      domain: { hex: "#C81E32" },
      _allSlugLocales: [
        { value: "grundbildung-banking", locale: "de" },
        { value: "formation-de-base-banque", locale: "fr" },
        { value: "formazione-di-base-banca", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "Grundbildung Banking", locale: "de" },
        { value: "Formation de base – banque", locale: "fr" },
        { value: "Formazione di base – banca", locale: "it" },
      ],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1671533420-cyp_220917_165.jpg",
      },
    },
    {
      children: [
        {
          children: [
            {
              children: [],
              id: "92918195",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "faq-bfe", locale: "de" },
                { value: "faq-bfe", locale: "fr" },
              ],
              _allPageNameLocales: [
                { value: "FAQ: BFE", locale: "de" },
                { value: "FAQ: BFE", locale: "fr" },
              ],
              cover: null,
            },
            {
              children: [
                {
                  children: [],
                  id: "122607984",
                  tweak: "bfe-calculator",
                  native: "",
                  _allNativeLocales: [
                    { locale: "de", value: "" },
                    { locale: "fr", value: "" },
                  ],
                  hidden: true,
                  isVirtual: false,
                  suppressedLocales: "",
                  domain: { hex: "#C81E32" },
                  _allSlugLocales: [
                    { value: "preisrechner", locale: "de" },
                    { value: "calculateur", locale: "fr" },
                  ],
                  _allPageNameLocales: [
                    { value: "Preisrechner", locale: "de" },
                    { value: "Calculateur de prix", locale: "fr" },
                  ],
                  cover: null,
                },
              ],
              id: "92918177",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "moduluebersicht-bfe", locale: "de" },
                { value: "apercu-des-modules-bfe", locale: "fr" },
              ],
              _allPageNameLocales: [
                { value: "Modulübersicht BFE", locale: "de" },
                { value: "Aperçu des modules – BFE", locale: "fr" },
              ],
              cover: null,
            },
          ],
          id: "92918134",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "en", value: "" },
            { locale: "fr", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [
            { value: "banking-finance-essentials", locale: "de" },
            { value: "banking-finance-essentials", locale: "en" },
            { value: "banking-finance-essentials", locale: "fr" },
          ],
          _allPageNameLocales: [
            { value: "Banking & Finance Essentials", locale: "de" },
            { value: "Banking & Finance Essentials", locale: "en" },
            { value: "Banking & Finance Essentials", locale: "fr" },
          ],
          cover: {
            customData: { "fp-x": "0.52", "fp-y": "0.45", "fp-z": "1.15" },
            url: "https://www.datocms-assets.com/25771/1698909311-cyp-2306_060.tif",
          },
        },
        {
          children: [
            {
              children: [],
              id: "140356843",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "basic-bivo-2012", locale: "de" },
                { value: "basic-orfo-2012", locale: "fr" },
                { value: "basic-ofro-2012", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Modul Basic BiVo 2012", locale: "de" },
                { value: "Module Basic Orfo 2012", locale: "fr" },
                { value: "Modulo Basic Ofro 2012", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918201",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "basic", locale: "de" },
                { value: "basic", locale: "fr" },
                { value: "basic", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Modul Basic BiVo 2023", locale: "de" },
                { value: "Module Basic Orfo 2023", locale: "fr" },
                { value: "Modulo Basic Ofro 2023", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918207",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "advanced", locale: "de" },
                { value: "advanced", locale: "fr" },
                { value: "advanced", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Modul Advanced", locale: "de" },
                { value: "Module Advanced", locale: "fr" },
                { value: "Modulo Advanced", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918208",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "top", locale: "de" },
                { value: "top", locale: "fr" },
                { value: "top", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Modul Top", locale: "de" },
                { value: "Module Top", locale: "fr" },
                { value: "Modulo Top", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "122375529",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "kv-reform-kompakt", locale: "de" },
                { value: "reforme-fcb-compact", locale: "fr" },
                { value: "riforma-fcb-compact", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "KV-Reform Kompakt", locale: "de" },
                { value: "Réforme FCB Compact", locale: "fr" },
                { value: "Riforma FCB Compact", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "KFWFa8gXTwqRts0iSRt7jw",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [{ value: "praxisausbilder-ict", locale: "de" }],
              _allPageNameLocales: [
                { value: "Praxisausbilder ICT", locale: "de" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918209",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "diplom", locale: "de" },
                { value: "diplome", locale: "fr" },
                { value: "diploma", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "SBFI-Diplom", locale: "de" },
                { value: "Diplôme SEFRI", locale: "fr" },
                { value: "Diploma SEFRI", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918181",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "pab-angebot", locale: "de" },
                { value: "pab-offre", locale: "fr" },
              ],
              _allPageNameLocales: [
                { value: "PAB Angebot", locale: "de" },
                {
                  value: "Offre de cours Formateurs pratiques (PAB)",
                  locale: "fr",
                },
              ],
              cover: null,
            },
            {
              children: [],
              id: "J7_6EkTBQVO_2zZr0KhriQ",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [{ value: "coaching-essentials", locale: "de" }],
              _allPageNameLocales: [
                {
                  value:
                    " Coaching für die Zukunft: Lernende in herausfordernden Zeiten erfolgreich unterstützen",
                  locale: "de",
                },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1727784001-cyp-2306_065.jpg",
              },
            },
            {
              children: [],
              id: "120414837",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                {
                  value: "workshop-mentale-staerkung-jugendliche",
                  locale: "de",
                },
                {
                  value: "workshop-mental-empowerment-young-people",
                  locale: "fr",
                },
                {
                  value: "workshop-rafforzamento-psicologico-adolescenti",
                  locale: "it",
                },
              ],
              _allPageNameLocales: [
                {
                  value: "Workshop «Mentale Stärkung von Jugendlichen»",
                  locale: "de",
                },
                {
                  value: "Workshop « Mental empowerment of young people »",
                  locale: "fr",
                },
                {
                  value:
                    "Workshop «Rafforzamento psicologico degli adolescenti»",
                  locale: "it",
                },
              ],
              cover: null,
            },
            {
              children: [],
              id: "BkZVrOmzQHSYebtqkvyF-Q",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "meine-rolle-als-coach", locale: "de" },
              ],
              _allPageNameLocales: [
                { value: "Meine Rolle als Coach", locale: "de" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918184",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "praesenzkursbesuch", locale: "de" },
                { value: "visite-de-cours-de-presence", locale: "fr" },
                { value: "assistere-ad-un-corso-di-presenza", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Besuchen Sie einen Präsenzkurs", locale: "de" },
                { value: "Visite de cours de présence", locale: "fr" },
                { value: "Assistere ad un corso di presenza", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1671535315-cyp_220917_038.jpg",
              },
            },
            {
              children: [],
              id: "CoWHQfp7QMa4FxwN9HtYNw",
              tweak: "",
              native: "",
              _allNativeLocales: [{ locale: "de", value: "" }],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "praxisauftraege-in-der-praxis", locale: "de" },
              ],
              _allPageNameLocales: [{ value: "Praxisaufträge", locale: "de" }],
              cover: null,
            },
            {
              children: [],
              id: "92918183",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "video-tutorial-cypnet-pab", locale: "de" },
                { value: "video-tutorial-cypnet-pab", locale: "fr" },
                { value: "video-tutorial-cypnet-pab", locale: "it" },
              ],
              _allPageNameLocales: [
                {
                  value: "Video-Tutorial CYPnet für Praxisausbilder",
                  locale: "de",
                },
                {
                  value: "Video-Tutorial CYPnet pour formateurs pratiques",
                  locale: "fr",
                },
                {
                  value: "Video-Tutorial CYPnet per Formatori di pratica",
                  locale: "it",
                },
              ],
              cover: null,
            },
            {
              children: [
                {
                  children: [],
                  id: "92918202",
                  tweak: "",
                  native: "",
                  _allNativeLocales: [
                    { locale: "de", value: "" },
                    { locale: "fr", value: "" },
                    { locale: "it", value: "" },
                  ],
                  hidden: true,
                  isVirtual: false,
                  suppressedLocales: "",
                  domain: { hex: "#C81E32" },
                  _allSlugLocales: [
                    { value: "instrumente-methoden", locale: "de" },
                    { value: "instruments-methodes", locale: "fr" },
                    { value: "strumenti-metodi", locale: "it" },
                  ],
                  _allPageNameLocales: [
                    { value: "Instrumente & Methoden", locale: "de" },
                    { value: "Instruments / Méthodes", locale: "fr" },
                    { value: "Strumenti / Metodi", locale: "it" },
                  ],
                  cover: null,
                },
              ],
              id: "92918187",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "cypedia", locale: "de" },
                { value: "cypedia", locale: "fr" },
                { value: "cypedia", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "CYPedia", locale: "de" },
                { value: "CYPedia", locale: "fr" },
                { value: "CYPedia", locale: "it" },
              ],
              cover: null,
            },
          ],
          id: "92918135",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [
            { value: "praxisausbilder", locale: "de" },
            { value: "formateurs-pratiques", locale: "fr" },
            { value: "formatori-di-pratica", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Praxisausbilder", locale: "de" },
            { value: "Formateurs pratiques", locale: "fr" },
            { value: "Formatori di pratica", locale: "it" },
          ],
          cover: {
            customData: { "fp-x": "0.6", "fp-y": "0.4", "fp-z": "1.2" },
            url: "https://www.datocms-assets.com/25771/1671533458-cyp_220917_288.jpg",
          },
        },
        {
          children: [
            {
              children: [],
              id: "92918178",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "fr,it",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "anmeldung", locale: "de" },
                { value: "inscription", locale: "fr" },
                { value: "iscrizione", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Anmeldung", locale: "de" },
                { value: "Inscription", locale: "fr" },
                { value: "Iscrizione", locale: "it" },
              ],
              cover: null,
            },
            {
              children: [],
              id: "92918197",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#C81E32" },
              _allSlugLocales: [
                { value: "fuer-firmen", locale: "de" },
                { value: "pour-les-entreprises", locale: "fr" },
                { value: "per-le-aziende", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "für Firmen", locale: "de" },
                { value: "Pour les entreprises", locale: "fr" },
                { value: "Per le aziende", locale: "it" },
              ],
              cover: {
                customData: { "fp-y": "0.4", "fp-z": "1.1" },
                url: "https://www.datocms-assets.com/25771/1671802457-cyp_220917_191.jpg",
              },
            },
          ],
          id: "92918151",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [
            { value: "skills40", locale: "de" },
            { value: "skills40", locale: "fr" },
            { value: "skills40", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Skills 4.0", locale: "de" },
            { value: "Skills 4.0", locale: "fr" },
            { value: "Skills 4.0", locale: "it" },
          ],
          cover: {
            customData: { "fp-y": "0.4", "fp-z": "1.1" },
            url: "https://www.datocms-assets.com/25771/1671802457-cyp_220917_191.jpg",
          },
        },
      ],
      id: "92918237",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "en", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: false,
      isVirtual: true,
      suppressedLocales: "",
      domain: { hex: "#C81E32" },
      _allSlugLocales: [
        { value: "weiterbildung-banking", locale: "de" },
        { value: "further-education", locale: "en" },
        { value: "formation-continue", locale: "fr" },
        { value: "formazione-continua", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "Weiterbildung Banking", locale: "de" },
        { value: "Further Education", locale: "en" },
        { value: "Formation continue", locale: "fr" },
        { value: "Formazione continua", locale: "it" },
      ],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1671793425-cyp_220917_102-bearbeitet.jpg",
      },
    },
    {
      children: [
        {
          children: [],
          id: "176782323",
          tweak: "",
          native: "",
          _allNativeLocales: [{ locale: "de", value: "" }],
          hidden: true,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#C81E32" },
          _allSlugLocales: [{ value: "cyp-forum", locale: "de" }],
          _allPageNameLocales: [{ value: "CYP Forum", locale: "de" }],
          cover: {
            customData: {},
            url: "https://www.datocms-assets.com/25771/1724153170-trust-in-ai_pavel-danilyuk.jpg",
          },
        },
      ],
      id: "92918239",
      tweak: "",
      native: "news-events/events",
      _allNativeLocales: [
        { locale: "de", value: "news-events/events" },
        { locale: "en", value: "news-events/events" },
        { locale: "fr", value: "news-events/events" },
        { locale: "it", value: "news-events/events" },
      ],
      hidden: false,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "events", locale: "de" },
        { value: "events", locale: "en" },
        { value: "events", locale: "fr" },
        { value: "events", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "Events", locale: "de" },
        { value: "Events", locale: "en" },
        { value: "Events", locale: "fr" },
        { value: "Events", locale: "it" },
      ],
      cover: null,
    },
    {
      children: [
        {
          children: [],
          id: "92918191",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "en", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "ueber-uns", locale: "de" },
            { value: "about-us", locale: "en" },
            { value: "a-propos-de-nous", locale: "fr" },
            { value: "chi-siamo", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Über uns", locale: "de" },
            { value: "About us", locale: "en" },
            { value: "À propos de nous", locale: "fr" },
            { value: "Chi siamo", locale: "it" },
          ],
          cover: null,
        },
        {
          children: [],
          id: "e_PeAfg7R1WAeTq-p0p64w",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "en", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#A42422" },
          _allSlugLocales: [
            { value: "bildungsdesign-cyp-de", locale: "de" },
            { value: "educational-concept_", locale: "en" },
            { value: "concept-formation", locale: "fr" },
            { value: "concetto-formazione", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Bildungsdesign", locale: "de" },
            { value: "Educational Concept (duplicate)", locale: "en" },
            { value: "Concept de formation (duplicate)", locale: "fr" },
            { value: "Concetto di formazione (duplicate)", locale: "it" },
          ],
          cover: {
            customData: {},
            url: "https://www.datocms-assets.com/25771/1724758652-cyp-2306_276.jpg",
          },
        },
        {
          children: [
            {
              children: [],
              id: "92918163",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: false,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "jobs", locale: "de" },
                { value: "candidatures", locale: "fr" },
                { value: "candidatura", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Jobs", locale: "de" },
                { value: "Candidatures", locale: "fr" },
                { value: "Candidatura", locale: "it" },
              ],
              cover: null,
            },
          ],
          id: "92918142",
          tweak: "",
          native: "cyp/teams",
          _allNativeLocales: [
            { locale: "de", value: "cyp/teams" },
            { locale: "en", value: "cyp/teams" },
            { locale: "fr", value: "cyp/teams" },
            { locale: "it", value: "cyp/teams" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "teams", locale: "de" },
            { value: "teams", locale: "en" },
            { value: "teams", locale: "fr" },
            { value: "teams", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Teams", locale: "de" },
            { value: "Teams", locale: "en" },
            { value: "Équipes", locale: "fr" },
            { value: "Team", locale: "it" },
          ],
          cover: {
            customData: {},
            url: "https://www.datocms-assets.com/25771/1592853646-cyp1804044.jpg",
          },
        },
        {
          children: [],
          id: "92918192",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "partner", locale: "de" },
            { value: "partenaires", locale: "fr" },
            { value: "partner", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Partner", locale: "de" },
            { value: "Partenaires", locale: "fr" },
            { value: "Partner", locale: "it" },
          ],
          cover: null,
        },
        {
          children: [],
          id: "92918193",
          tweak: "",
          native: "cyp/medien",
          _allNativeLocales: [
            { locale: "de", value: "cyp/medien" },
            { locale: "fr", value: "cyp/medien" },
            { locale: "it", value: "cyp/medien" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "medien", locale: "de" },
            { value: "medias", locale: "fr" },
            { value: "media", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Medien", locale: "de" },
            { value: "Médias", locale: "fr" },
            { value: "Media", locale: "it" },
          ],
          cover: null,
        },
        {
          children: [
            {
              children: [],
              id: "92918212",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "2017", locale: "de" },
                { value: "2017", locale: "fr" },
                { value: "2017", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Geschäftsbericht 2017", locale: "de" },
                { value: "Rapport de gestion 2017", locale: "fr" },
                { value: "Rapporto di gestione 2017", locale: "it" },
              ],
              cover: null,
            },
          ],
          id: "92918194",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "geschaeftsbericht", locale: "de" },
            { value: "rapport-de-gestion", locale: "fr" },
            { value: "rapporto-di-gestione", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Geschäftsbericht", locale: "de" },
            { value: "Rapport de gestion", locale: "fr" },
            { value: "Rapporto di gestione", locale: "it" },
          ],
          cover: null,
        },
        {
          children: [
            {
              children: [],
              id: "92918165",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "zuerich", locale: "de" },
                { value: "zuerich", locale: "en" },
                { value: "zuerich", locale: "fr" },
                { value: "zuerich", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Zürich", locale: "de" },
                { value: "Zurich", locale: "en" },
                { value: "Zurich", locale: "fr" },
                { value: "Zurigo", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943048-map-zuerich.jpg",
              },
            },
            {
              children: [],
              id: "92918166",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "aarau", locale: "de" },
                { value: "aarau", locale: "en" },
                { value: "aarau", locale: "fr" },
                { value: "aarau", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Aarau", locale: "de" },
                { value: "Aarau", locale: "en" },
                { value: "Aarau", locale: "fr" },
                { value: "Aarau", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943073-map-aarau.jpg",
              },
            },
            {
              children: [],
              id: "92918167",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "basel", locale: "de" },
                { value: "basel", locale: "en" },
                { value: "basel", locale: "fr" },
                { value: "basel", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Basel", locale: "de" },
                { value: "Basel", locale: "en" },
                { value: "Bâle", locale: "fr" },
                { value: "Basilea", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943106-map-basel.jpg",
              },
            },
            {
              children: [],
              id: "92918168",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "bern", locale: "de" },
                { value: "bern", locale: "en" },
                { value: "bern", locale: "fr" },
                { value: "bern", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Bern", locale: "de" },
                { value: "Bern", locale: "en" },
                { value: "Berne", locale: "fr" },
                { value: "Berna", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943116-map-bern.jpg",
              },
            },
            {
              children: [],
              id: "92918169",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "chur-grossratsgebaeude", locale: "de" },
                { value: "chur-grossratsgebaeude", locale: "en" },
                { value: "chur-grossratsgebaeude", locale: "fr" },
                { value: "chur-grossratsgebaeude", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Chur Grossratsgebäude", locale: "de" },
                { value: "Chur Grossratsgebäude", locale: "en" },
                { value: "Chur Grossratsgebäude", locale: "fr" },
                { value: "Chur Grossratsgebäude", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943077-map-chur-grossrat.png",
              },
            },
            {
              children: [],
              id: "92918170",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "chur-klubschule", locale: "de" },
                { value: "chur-klubschule", locale: "en" },
                { value: "chur-klubschule", locale: "fr" },
                { value: "chur-klubschule", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Chur Klubschule", locale: "de" },
                { value: "Chur Klubschule", locale: "en" },
                { value: "Chur Klubschule", locale: "fr" },
                { value: "Chur Klubschule", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943080-map-chur-klubschule.png",
              },
            },
            {
              children: [],
              id: "92918171",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "luzern", locale: "de" },
                { value: "luzern", locale: "en" },
                { value: "luzern", locale: "fr" },
                { value: "luzern", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Luzern", locale: "de" },
                { value: "Lucerne", locale: "en" },
                { value: "Lucerne", locale: "fr" },
                { value: "Lucerna", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943084-map-luzern.png",
              },
            },
            {
              children: [],
              id: "92918172",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "st-gallen", locale: "de" },
                { value: "st-gallen", locale: "en" },
                { value: "st-gallen", locale: "fr" },
                { value: "st-gallen", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "St. Gallen", locale: "de" },
                { value: "St. Gallen", locale: "en" },
                { value: "Saint-Gall", locale: "fr" },
                { value: "San Gallo", locale: "it" },
              ],
              cover: {
                customData: { "fp-x": "0.3", "fp-z": "1" },
                url: "https://www.datocms-assets.com/25771/1653984922-map-stgallen.jpg",
              },
            },
            {
              children: [],
              id: "92918173",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "triesen", locale: "de" },
                { value: "triesen", locale: "en" },
                { value: "triesen", locale: "fr" },
                { value: "triesen", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Triesen (FL)", locale: "de" },
                { value: "Triesen (FL)", locale: "en" },
                { value: "Triesen (FL)", locale: "fr" },
                { value: "Triesen (FL)", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943090-map-triesen.jpg",
              },
            },
            {
              children: [
                {
                  children: [],
                  id: "92918221",
                  tweak: "",
                  native: "",
                  _allNativeLocales: [
                    { locale: "de", value: "" },
                    { locale: "en", value: "" },
                    { locale: "fr", value: "" },
                    { locale: "it", value: "" },
                  ],
                  hidden: true,
                  isVirtual: false,
                  suppressedLocales: "",
                  domain: { hex: "#000000" },
                  _allSlugLocales: [
                    { value: "raumvermietung-lausanne", locale: "de" },
                    { value: "room-rental-lausanne", locale: "en" },
                    { value: "location-des-salles-lausanne", locale: "fr" },
                    { value: "affitto-aule-losanna", locale: "it" },
                  ],
                  _allPageNameLocales: [
                    { value: "Raumvermietung Lausanne", locale: "de" },
                    { value: "Room rental Lausanne", locale: "en" },
                    { value: "Location des salles Lausanne", locale: "fr" },
                    { value: "Affitto aule – Losanna", locale: "it" },
                  ],
                  cover: null,
                },
              ],
              id: "92918174",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "lausanne", locale: "de" },
                { value: "lausanne", locale: "en" },
                { value: "lausanne", locale: "fr" },
                { value: "lausanne", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Lausanne", locale: "de" },
                { value: "Lausanne", locale: "en" },
                { value: "Lausanne", locale: "fr" },
                { value: "Losanna", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943094-map-lausanne.jpg",
              },
            },
            {
              children: [],
              id: "92918175",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "genf", locale: "de" },
                { value: "geneve", locale: "en" },
                { value: "geneve", locale: "fr" },
                { value: "geneve", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Genf", locale: "de" },
                { value: "Geneva", locale: "en" },
                { value: "Genève", locale: "fr" },
                { value: "Ginevra", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943103-map-geneve.jpg",
              },
            },
            {
              children: [],
              id: "92918176",
              tweak: "",
              native: "",
              _allNativeLocales: [
                { locale: "de", value: "" },
                { locale: "en", value: "" },
                { locale: "fr", value: "" },
                { locale: "it", value: "" },
              ],
              hidden: true,
              isVirtual: false,
              suppressedLocales: "",
              domain: { hex: "#000000" },
              _allSlugLocales: [
                { value: "vezia", locale: "de" },
                { value: "vezia", locale: "en" },
                { value: "vezia", locale: "fr" },
                { value: "vezia", locale: "it" },
              ],
              _allPageNameLocales: [
                { value: "Vezia", locale: "de" },
                { value: "Vezia", locale: "en" },
                { value: "Vézia", locale: "fr" },
                { value: "Vezia", locale: "it" },
              ],
              cover: {
                customData: {},
                url: "https://www.datocms-assets.com/25771/1593943087-map-vezia.png",
              },
            },
          ],
          id: "92918164",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "en", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "standorte", locale: "de" },
            { value: "locations", locale: "en" },
            { value: "plans-de-situation", locale: "fr" },
            { value: "luoghi-del-cyp", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Standorte", locale: "de" },
            { value: "Locations", locale: "en" },
            { value: "Plans de situation", locale: "fr" },
            { value: "Luoghi del CYP", locale: "it" },
          ],
          cover: null,
        },
        {
          children: [],
          id: "92918147",
          tweak: "blog",
          native: "blog",
          _allNativeLocales: [
            { locale: "de", value: "blog" },
            { locale: "en", value: "blog" },
            { locale: "fr", value: "blog" },
            { locale: "it", value: "blog" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "blog", locale: "de" },
            { value: "blog", locale: "en" },
            { value: "blog", locale: "fr" },
            { value: "blog", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Blog", locale: "de" },
            { value: "Blog", locale: "en" },
            { value: "Blog", locale: "fr" },
            { value: "Blog", locale: "it" },
          ],
          cover: null,
        },
        {
          children: [],
          id: "92918144",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "en", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: false,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "kontakt", locale: "de" },
            { value: "contact", locale: "en" },
            { value: "contact", locale: "fr" },
            { value: "contatto", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "Kontakt", locale: "de" },
            { value: "Contact", locale: "en" },
            { value: "Contact", locale: "fr" },
            { value: "Contatto", locale: "it" },
          ],
          cover: null,
        },
      ],
      id: "92918240",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "en", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: false,
      isVirtual: true,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "cyp", locale: "de" },
        { value: "cyp", locale: "en" },
        { value: "cyp", locale: "fr" },
        { value: "cyp", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "CYP", locale: "de" },
        { value: "CYP", locale: "en" },
        { value: "CYP", locale: "fr" },
        { value: "CYP", locale: "it" },
      ],
      cover: null,
    },
    {
      children: [],
      id: "92918145",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "en", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "impressum", locale: "de" },
        { value: "imprint", locale: "en" },
        { value: "impressum", locale: "fr" },
        { value: "impressum", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "Impressum", locale: "de" },
        { value: "Imprint", locale: "en" },
        { value: "Impressum", locale: "fr" },
        { value: "Impressum", locale: "it" },
      ],
      cover: null,
    },
    {
      children: [],
      id: "92918146",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "en", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "datenschutz", locale: "de" },
        { value: "data-privacy", locale: "en" },
        { value: "prot-donnees", locale: "fr" },
        { value: "protezione-dei-dati", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "Datenschutz", locale: "de" },
        { value: "Data Privacy", locale: "en" },
        { value: "Déclaration de protection des données", locale: "fr" },
        { value: "Dichiarazione sulla protezione dei dati", locale: "it" },
      ],
      cover: null,
    },
    {
      children: [],
      id: "92918223",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "en", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "agb", locale: "de" },
        { value: "agb", locale: "en" },
        { value: "agb", locale: "fr" },
        { value: "agb", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "AGB", locale: "de" },
        { value: "Terms & Conditions", locale: "en" },
        { value: "Conditions générales", locale: "fr" },
        { value: "Condizioni generali", locale: "it" },
      ],
      cover: null,
    },
    {
      children: [
        {
          children: [],
          id: "92918234",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "fr", value: "" },
            { locale: "it", value: "" },
          ],
          hidden: true,
          isVirtual: false,
          suppressedLocales: "",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "transformation", locale: "de" },
            { value: "transformation", locale: "fr" },
            { value: "transformation", locale: "it" },
          ],
          _allPageNameLocales: [
            { value: "CYP Transformation", locale: "de" },
            { value: "CYP Transformation", locale: "fr" },
            { value: "CYP Transformation", locale: "it" },
          ],
          cover: null,
        },
      ],
      id: "92918235",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: true,
      isVirtual: true,
      suppressedLocales: "de, fr, it",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "legacy", locale: "de" },
        { value: "legacy", locale: "fr" },
        { value: "legacy", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "Legacy", locale: "de" },
        { value: "Legacy", locale: "fr" },
        { value: "Legacy", locale: "it" },
      ],
      cover: null,
    },
    {
      children: [],
      id: "92918159",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "en", value: "" },
        { locale: "fr", value: "" },
        { locale: "it", value: "" },
      ],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#A42422" },
      _allSlugLocales: [
        { value: "debug", locale: "de" },
        { value: "debug", locale: "en" },
        { value: "debug", locale: "fr" },
        { value: "debug", locale: "it" },
      ],
      _allPageNameLocales: [
        { value: "debug", locale: "de" },
        { value: "debug", locale: "en" },
        { value: "debug", locale: "fr" },
        { value: "debug", locale: "it" },
      ],
      cover: {
        customData: { "fp-x": "0.6", "fp-z": "1.2", "fp-debug": "true" },
        url: "https://www.datocms-assets.com/22855/1584544291-you-x-ventures-tho1oukbg0-unsplash.jpg",
      },
    },
    {
      children: [
        {
          children: [],
          id: "92918233",
          tweak: "",
          native: "",
          _allNativeLocales: [
            { locale: "de", value: "" },
            { locale: "en", value: "" },
            { locale: "fr", value: "" },
          ],
          hidden: true,
          isVirtual: false,
          suppressedLocales: "en, fr",
          domain: { hex: "#000000" },
          _allSlugLocales: [
            { value: "xefw77a", locale: "de" },
            { value: "xefw77a", locale: "en" },
            { value: "xefw77a", locale: "fr" },
          ],
          _allPageNameLocales: [
            { value: "[DEV] Structured Text", locale: "de" },
            { value: "[DEV] Structured Text", locale: "en" },
            { value: "[DEV] Structured Text", locale: "fr" },
          ],
          cover: null,
        },
      ],
      id: "92918230",
      tweak: "",
      native: "",
      _allNativeLocales: [
        { locale: "de", value: "" },
        { locale: "fr", value: "" },
      ],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "en, it",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "wakzmuk", locale: "de" },
        { value: "wakzmuk", locale: "fr" },
      ],
      _allPageNameLocales: [
        { value: "[DEV] Test Page", locale: "de" },
        { value: "Test Page", locale: "fr" },
      ],
      cover: null,
    },
    {
      children: [],
      id: "B7BpRD82TWe2wuLPFxdLFQ",
      tweak: "",
      native: "",
      _allNativeLocales: [{ locale: "de", value: "" }],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [{ value: "leitlinien-de", locale: "de" }],
      _allPageNameLocales: [
        {
          value: "Welcher Philosophie folgt das Lernen bei CYP?  ",
          locale: "de",
        },
      ],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1724761585-cyp-2306_054.jpg",
      },
    },
    {
      children: [],
      id: "YZcmCcJORVqLgHMKmwq4Fg",
      tweak: "",
      native: "",
      _allNativeLocales: [{ locale: "de", value: "" }],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#A42422" },
      _allSlugLocales: [{ value: "bildungsdesign-de", locale: "de" }],
      _allPageNameLocales: [{ value: "CYP Module", locale: "de" }],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1724761262-cyp-2306_209.jpg",
      },
    },
    {
      children: [],
      id: "ZH3YOWEWQFqhQ4p7FPbAxA",
      tweak: "",
      native: "",
      _allNativeLocales: [{ locale: "de", value: "" }],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#A42422" },
      _allSlugLocales: [{ value: "trainer-de", locale: "de" }],
      _allPageNameLocales: [{ value: "Trainer:innen", locale: "de" }],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1724761425-cyp-2306_122.jpg",
      },
    },
    {
      children: [],
      id: "acDCjI7KTae0kkgeq7A6fg",
      tweak: "",
      native: "",
      _allNativeLocales: [{ locale: "de", value: "" }],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#A42422" },
      _allSlugLocales: [{ value: "lernmethoden-de", locale: "de" }],
      _allPageNameLocales: [{ value: "Lernmethoden", locale: "de" }],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1724761726-cyp_220917_157.jpg",
      },
    },
    {
      children: [],
      id: "Edv_DOihTnetmvLwcU-DPQ",
      tweak: "",
      native: "",
      _allNativeLocales: [{ locale: "de", value: "" }],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [{ value: "leistungen-im-modulpreis", locale: "de" }],
      _allPageNameLocales: [
        { value: "Leistungen im Modulpreis", locale: "de" },
      ],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1733402320-cyp-2306_343.jpg",
      },
    },
    {
      children: [],
      id: "FjN62vLCR22lxbvszqVuLA",
      tweak: "",
      native: "",
      _allNativeLocales: [{ locale: "de", value: "" }],
      hidden: true,
      isVirtual: false,
      suppressedLocales: "",
      domain: { hex: "#000000" },
      _allSlugLocales: [
        { value: "aktuelles-update-bfe-lehrgang", locale: "de" },
      ],
      _allPageNameLocales: [
        {
          value: "Update für den Lehrgang Banking & Finance Essentials",
          locale: "de",
        },
      ],
      cover: {
        customData: {},
        url: "https://www.datocms-assets.com/25771/1734013530-bfe_update_2025.svg",
      },
    },
  ] as any;

export const contentInfo: GQL.ContentPageStructureQuery["contentInfo"] = {
  contentPageLine: [],
} as any;
